const handleHeaderMenuItems = () => {
    const headerMenuItems = window.document.querySelectorAll('.js-header-menu .menu li a');
    if (!headerMenuItems)
        return;
    [...headerMenuItems].forEach((item) => {
        const linkHtml = item.innerHTML;
        const span1 = document.createElement('span');
        span1.className = 'hover-btn__init-text';
        span1.innerHTML = linkHtml;
        const span2 = document.createElement('span');
        span2.className = 'hover-btn__transparent-text';
        span2.innerHTML = linkHtml;
        const span3 = document.createElement('span');
        span3.className = 'hover-btn__hover-text';
        span3.innerHTML = linkHtml;
        item.classList.add('hover-btn');
        item.innerHTML = '';
        item.append(span1);
        item.append(span2);
        item.append(span3);
    });
};
export default handleHeaderMenuItems;
