const handlePopupBackBtnSignUpEarlyAccess = () => {
    const signUpOptionsWrap = window.document.querySelector('.js-sign-up-options-wrap');
    const signUpFormWrap = window.document.querySelector('.js-popup-sign-up-form-wrap');
    const popupBackBtn = window.document.querySelector('.js-popup-back-btn');
    if (!signUpOptionsWrap || !signUpFormWrap || !popupBackBtn)
        return;
    popupBackBtn.addEventListener('click', () => {
        signUpOptionsWrap.classList.add('active');
        signUpFormWrap.classList.remove('active');
    });
};
export default handlePopupBackBtnSignUpEarlyAccess;
