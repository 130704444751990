const handleSentEarlyAccessForm = (contactFormId = 0, popupInstance = null) => {
    window.document.addEventListener('wpcf7submit', (event) => {
        event.preventDefault();
        const formEvent = event.detail;
        if (+contactFormId === formEvent.contactFormId) {
            const { formData } = formEvent;
            formData.append('action', 'send_early_access_data');
            fetch(var_from_php.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                if (data.success) {
                    if (!popupInstance)
                        return;
                    popupInstance.forceCloseAllPopup();
                    popupInstance.openOnePopup('#popup-thanks-message');
                }
            });
            window.lintrk && window.lintrk('track', { conversion_id: 17711993 });
        }
    }, false);
};
export default handleSentEarlyAccessForm;
