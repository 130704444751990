const scrollBtnTop = () => {
    const scrollToTopBtn = window.document.querySelector('.js-scroll-to-top');
    if (!scrollToTopBtn)
        return;
    scrollToTopBtn.addEventListener('click', (event) => {
        event.preventDefault();
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 1000);
    });
};
export default scrollBtnTop;
