const handleSentExpertForm = (contactFormId1, contactFormId2) => {
    const forms = document.querySelectorAll('.js-expert-form form');
    const handleFocusEvent = (event) => {
        const targetInput = event.target;
        const parentElem = targetInput.closest('.wpcf7-form-control-wrap');
        if (!parentElem)
            return;
        parentElem.classList.add('focused');
    };
    const handleBlurEvent = () => {
        setTimeout(() => {
            forms.forEach((form) => {
                const fields = form.querySelectorAll('input, textarea, select');
                fields.forEach((input) => {
                    const actionType = input.classList.contains('wpcf7-not-valid') ? 'add' : 'remove';
                    const formInputWrap = input.closest('.wpcf7-form-control-wrap');
                    if (!formInputWrap)
                        return;
                    formInputWrap.classList.remove('focused');
                    formInputWrap.classList[actionType]('not-valid');
                });
            });
        }, 50);
    };
    window.document.addEventListener('wpcf7mailsent', (event) => {
        event.preventDefault();
        const formEvent = event.detail;
        const expertFormStart = document.querySelector('.js-expert-form-start');
        const expertFormThanks = document.querySelector('.js-expert-form-thanks');
        if ((+contactFormId1 === formEvent.contactFormId || +contactFormId2 === formEvent.contactFormId) &&
            expertFormStart &&
            expertFormThanks) {
            expertFormStart.classList.toggle('active');
            expertFormThanks.classList.toggle('active');
        }
    }, false);
    window.document.addEventListener('wpcf7submit', () => {
        handleBlurEvent();
    }, false);
    forms.forEach((form) => {
        const inputs = form.querySelectorAll('input, textarea, select');
        inputs.forEach((input) => {
            input.addEventListener('focus', handleFocusEvent);
        });
        inputs.forEach((input) => {
            input.addEventListener('blur', handleBlurEvent);
        });
    });
};
export default handleSentExpertForm;
