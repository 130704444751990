import Masonry from 'masonry-layout';
const initDevotedCommunityGallery = () => {
    const grid = document.querySelector('.dev-community__items');
    if (!grid)
        return;
    const devCommunity = new Masonry(grid, {
        itemSelector: '.dev-community__item',
        columnWidth: 160,
    });
    console.log('devCommunity', devCommunity);
};
export default initDevotedCommunityGallery;
