const handleJoinDevotedCard = () => {
    const joinDevotedItems = document.querySelectorAll('.js-join-devoted-item');
    joinDevotedItems &&
        joinDevotedItems.forEach((item) => {
            item.addEventListener('mouseenter', (event) => {
                if (window.innerWidth > 991) {
                    joinDevotedItems.forEach((joinItem) => {
                        joinItem.classList.remove('active');
                    });
                    event.target.classList.add('active');
                }
            });
            item.addEventListener('mouseleave', () => {
                if (window.innerWidth > 991) {
                    joinDevotedItems.forEach((joinItem, index) => {
                        const actionType = index === 0 ? 'add' : 'remove';
                        joinItem.classList[actionType]('active');
                    });
                }
            });
        });
};
export default handleJoinDevotedCard;
