/* eslint-disable */
class BlogDataHandler {
    constructor(var_from_php) {
        this.ajaxUrl = var_from_php.ajax_url;
        this.defaultPostOffset = 5;
    }

    get_first_blog_posts(targetCategory) {
        const data = new FormData();
        data.append('action', 'get_first_blog_posts');
        data.append('category_name', targetCategory);
        data.append('default_post_offset', '0');

        return fetch(this.ajaxUrl, {
            method: "POST",
            credentials: 'same-origin',
            body: data
        })
            .then(response => response.json())
            .then(data => {
                this.handleFirstBlogPosts(data);
                this.handleCategoryPostCount(data);
            });
    }

    handleCategoryPostCount(data) {
        const topicsBtn = document.querySelector('.js-blog-load-btn');
        const topicsBtnWrap = document.querySelector('.js-topics-btn-wrap');
        const defaultPostOffset = this.defaultPostOffset;

        if (data.success && data.data) {
            const actionType = +defaultPostOffset < data.data.count ? 'add' : 'remove';
            topicsBtn.dataset.postCounts = data.data.count;
            topicsBtnWrap.classList[actionType]('active');
        }
    }

    handleFirstBlogPosts(data) {
        const blogPostsContainer = document.querySelector('.js-blog-posts');

        if (data.success && data.data) {
            blogPostsContainer.innerHTML = '';
            blogPostsContainer.insertAdjacentHTML('beforeend', data.data.posts);
        }
    }
}

export default BlogDataHandler;
/* eslint-enable */
