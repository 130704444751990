var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import '../scss/frontend.scss';
import Sliders from './components/swiper-init';
import Popup from './parts/popup-window';
import scrollBtnTop from './components/toggleScrollBtnTop';
import changeHeroBgImages from './components/changeHeroBgImages';
import initDevotedCommunityGallery from './components/initDevotedCommunityGallery';
import initIndustriesItemsSlider from './components/initIndustriesItemsSlider';
import handleSentExpertForm from './components/handleSentExpertForm';
import handleSentEarlyAccessForm from './components/handleSentEarlyAccessForm';
import SlickSliders from './components/slick-init';
import initIdentifyTalentGallery from './components/initIdentifyTalentGallery';
import BlogDataHandler from './parts/BlogDataHandler';
import handleJoinDevotedCard from './components/handleJoinDevotedCard';
import createBackBTnToPopupSignUpEarlyAccess from './components/createBackBTnToPopupSignUpEarlyAccess';
import handlePopupBackBtnSignUpEarlyAccess from './components/handlePopupBackBtnSignUpEarlyAccess';
import handleHeaderMenuItems from './components/handleHeaderMenuItems';
jQuery(document).ready(function () {
    return __awaiter(this, void 0, void 0, function* () {
        const popupInstance = new Popup();
        popupInstance.init();
        Sliders.initSwipers();
        scrollBtnTop();
        changeHeroBgImages();
        initDevotedCommunityGallery();
        initIdentifyTalentGallery();
        initIndustriesItemsSlider();
        handleSentExpertForm(var_from_php.expert_form_id, var_from_php.talk_to_us_form_id);
        handleSentEarlyAccessForm(var_from_php.early_access_id, popupInstance);
        handleJoinDevotedCard();
        createBackBTnToPopupSignUpEarlyAccess();
        handlePopupBackBtnSignUpEarlyAccess();
        handleHeaderMenuItems();
        SlickSliders.initSlickSliders(var_from_php);
        document.addEventListener('wpcf7mailsent', function (event) {
            const currentFormId = event.detail.contactFormId;
            const pathName = window.location.pathname;
            const currentPageSlug = pathName.replace(/^\/+|\/+$/g, '') || 'home-page';
            window.dataLayer = window.dataLayer || [];
            var_from_php.data_layer_options &&
                var_from_php.data_layer_options.forEach(dataLayerOption => {
                    const { form_id: formID, page_slug: pageSlug, event_name: eventName, event_param: eventPropertyName, event_param_val: eventPropertyValue, is_thanks_popup_at_the_end: isThanksPopupAtTheEnd } = dataLayerOption;
                    if (+currentFormId === +formID && currentPageSlug === pageSlug && !isThanksPopupAtTheEnd) {
                        dataLayer.push({
                            'event': eventName,
                            [eventPropertyName]: eventPropertyValue
                        });
                    }
                    if (+formID === +currentFormId && isThanksPopupAtTheEnd) {
                        popupInstance.forceCloseAllPopup();
                        popupInstance.openOnePopup('#popup-thanks-message');
                        dataLayer.push({
                            'event': eventName,
                            [eventPropertyName]: eventPropertyValue
                        });
                    }
                });
        }, false);
        const clickedOutsideShareBtn = (targetElement = null) => {
            const shareBtnWraps = window.document.querySelectorAll('.js-share-btn-wrap');
            if (!targetElement || !shareBtnWraps)
                return;
            [...shareBtnWraps].forEach(item => {
                if (targetElement !== item && !item.contains(targetElement)) {
                    const shareSocialBtns = item.querySelector('.js-share-social-btns');
                    if (!shareSocialBtns)
                        return;
                    shareSocialBtns.classList.remove('active');
                }
            });
        };
        document.body.addEventListener('click', (event) => {
            const target = event.target;
            const role = target.dataset.role;
            clickedOutsideShareBtn(target);
            if (!role)
                return;
            switch (role) {
                case 'toggle-burger-menu': {
                    const burgerMenu = window.document.querySelector('.js-burger-menu');
                    if (!burgerMenu)
                        return;
                    burgerMenu.classList.toggle('active');
                    window.document.body.classList.toggle('open-burger-menu');
                    break;
                }
                case 'toggle-faq-item': {
                    const faqItems = window.document.querySelectorAll('.js-faq-item');
                    if (!faqItems)
                        return;
                    const actionType = target.classList.contains('active') ? 'remove' : 'add';
                    [...faqItems].forEach(item => {
                        if (item === target)
                            return;
                        item.classList.remove('active');
                    });
                    target.classList[actionType]('active');
                    break;
                }
                case 'handle-popup-sign-up-options': {
                    const signUpEarlyAccessOptions = window.document.querySelectorAll('.js-sign-up-early-access-option');
                    const targetBtnSelector = target.dataset.btn;
                    const targetBtn = targetBtnSelector ? window.document.querySelector(`.${targetBtnSelector}`) : null;
                    const signUpEarlyAccessBtns = window.document.querySelectorAll(`.js-sign-up-early-access-btn`);
                    if (targetBtn && signUpEarlyAccessBtns) {
                        [...signUpEarlyAccessBtns].forEach(item => {
                            const actionType = item.classList.contains(targetBtnSelector) ? 'add' : 'remove';
                            item.classList[actionType]('active');
                        });
                    }
                    if (!signUpEarlyAccessOptions)
                        return;
                    [...signUpEarlyAccessOptions].forEach(item => {
                        item.classList.remove('active');
                    });
                    target.classList.add('active');
                    break;
                }
                case 'show-sign-up-early-access-form': {
                    event.preventDefault();
                    const signUpOptionsWrap = window.document.querySelector('.js-sign-up-options-wrap');
                    const signUpFormWrap = window.document.querySelector('.js-popup-sign-up-form-wrap');
                    if (!signUpOptionsWrap || !signUpFormWrap)
                        return;
                    signUpOptionsWrap.classList.remove('active');
                    signUpFormWrap.classList.add('active');
                    break;
                }
                case 'filter-category': {
                    const targetCategory = target.dataset.category;
                    const blogCategories = document.querySelectorAll('.js-blog-category');
                    if (!targetCategory)
                        return;
                    const dataHandler = new BlogDataHandler(var_from_php);
                    dataHandler.get_first_blog_posts(targetCategory);
                    [...blogCategories].forEach(item => {
                        const actionType = item === target ? 'add' : 'remove';
                        item.classList[actionType]('active');
                    });
                    break;
                }
                case 'filter-browse-talent': {
                    const targetTerm = target.dataset.term;
                    const targetTaxonomy = target.dataset.taxonomy;
                    const parentBrowseTalentTerms = target.closest('.js-browse-talent-terms');
                    const browseTalentTerms = parentBrowseTalentTerms
                        ? parentBrowseTalentTerms.querySelectorAll('.js-browse-talent')
                        : null;
                    if (!browseTalentTerms || !targetTerm || !targetTaxonomy)
                        return;
                    const data = new FormData();
                    data.append('action', 'filter_browse_talent');
                    data.append('target_term', targetTerm);
                    data.append('target_taxonomy', targetTaxonomy);
                    fetch(var_from_php.ajax_url, {
                        method: "POST",
                        credentials: 'same-origin',
                        body: data
                    })
                        .then((response) => response.json())
                        .then((data) => {
                        if (data.success) {
                            const parentItem = target.closest('.js-browse-talent-item');
                            const browseTalentCards = parentItem.querySelector('.js-browse-talent-cards');
                            if (!browseTalentCards)
                                return;
                            browseTalentCards.innerHTML = data.data;
                        }
                    });
                    [...browseTalentTerms].forEach(item => {
                        const actionType = (item === target)
                            ? 'add'
                            : 'remove';
                        item.classList[actionType]('active');
                    });
                    break;
                }
                case 'load-more-posts': {
                    event.preventDefault();
                    const currentOffset = target.dataset.offset;
                    const blogPosts = window.document.querySelector('.js-blog-posts');
                    const blogCategoryAll = window.document.querySelector('.js-blog-category-all');
                    if (!currentOffset || !blogPosts || !blogCategoryAll)
                        return;
                    const targetCategoryStr = blogCategoryAll.dataset.category;
                    const data = new FormData();
                    data.append('action', 'get_first_blog_posts');
                    data.append('default_post_offset', currentOffset);
                    data.append('category_name', targetCategoryStr);
                    fetch(var_from_php.ajax_url, {
                        method: "POST",
                        credentials: 'same-origin',
                        body: data
                    })
                        .then((response) => response.json())
                        .then((response) => {
                        if (response.success && response.data) {
                            blogPosts.insertAdjacentHTML('beforeend', response.data.posts);
                            target.dataset.offset = +currentOffset + +var_from_php.posts_per_page;
                            if ((+currentOffset + +var_from_php.posts_per_page) >= +var_from_php.posts_per_page) {
                                const topicsBtnWrap = window.document.querySelector('.js-topics-btn-wrap');
                                if (!topicsBtnWrap)
                                    return;
                                topicsBtnWrap.classList.remove('active');
                            }
                        }
                    });
                    break;
                }
                case 'copy-post-link': {
                    event.preventDefault();
                    const postURL = window.location.href;
                    const textarea = document.createElement('textarea');
                    textarea.value = postURL;
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textarea);
                    break;
                }
                case 'load-popular_posts': {
                    event.preventDefault();
                    const currentOffset = target.dataset.offset;
                    const initShowPosts = target.dataset.initShow;
                    const amountPopularPosts = target.dataset.popularPosts;
                    const popularPosts = window.document.querySelector('.js-popular-posts');
                    if (!currentOffset || !popularPosts || !amountPopularPosts || !initShowPosts)
                        return;
                    const data = new FormData();
                    data.append('action', 'load_popular_posts');
                    data.append('offset', +currentOffset);
                    fetch(var_from_php.ajax_url, {
                        method: "POST",
                        credentials: 'same-origin',
                        body: data
                    })
                        .then((response) => response.json())
                        .then((data) => {
                        if (data.success && data.data) {
                            popularPosts.insertAdjacentHTML('beforeend', data.data);
                            target.dataset.offset = +currentOffset + +initShowPosts;
                            if ((+currentOffset + +initShowPosts) >= +amountPopularPosts) {
                                const popularBtnWrap = window.document.querySelector('.js-popular-btn-wrap');
                                if (!popularBtnWrap)
                                    return;
                                popularBtnWrap.classList.remove('active');
                            }
                        }
                    });
                    break;
                }
                case 'post-share': {
                    event.preventDefault();
                    const parentBtnWrap = target.closest('.js-share-btn-wrap');
                    const shareSocialBtns = (parentBtnWrap)
                        ? parentBtnWrap.querySelector('.js-share-social-btns')
                        : null;
                    const actionType = (shareSocialBtns && shareSocialBtns.classList.contains('active')) ? 'remove' : 'add';
                    if (!shareSocialBtns)
                        return;
                    shareSocialBtns.classList[actionType]('active');
                    break;
                }
                default:
                    break;
            }
        });
        $(document).ready(function () {
            const validationEmailRule = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            const validateEmail = (email) => validationEmailRule.test(email);
            let $form = $('.footer-contact-us form');
            let data = {};
            function validates($this, data) {
                const val = $this.val();
                if (validateEmail(val)) {
                    $this.closest('.input-box').removeClass('invalid');
                    data['email'] = val;
                }
                else {
                    $this.closest('.input-box').addClass('invalid');
                    delete data['email'];
                }
                return data;
            }
            $form.find('input').on('keyup', function () {
                data = validates($(this), data);
            });
            $(document).on('click', '.footer-contact-us .btn', function (e) {
                e.preventDefault();
                $form.find('input').each(function () {
                    data = validates($(this), data);
                });
                if (!data.hasOwnProperty('email')) {
                    return;
                }
                data['action'] = 'create_email_action';
                $.ajax({
                    url: myajax.url,
                    type: 'get',
                    data: data,
                    success: function (postId) {
                        data = {};
                        $('.popup[data-popup="success-form"]').fadeIn();
                        $form.trigger("reset");
                    },
                    error: function (err) {
                        console.error(err);
                    }
                });
            });
            $(document).on('click', '.js-burger-menu-icon', () => {
                const burgerMenu = $('.js-burger-menu');
                if (!burgerMenu)
                    return;
                burgerMenu.toggleClass('active');
                $('body').toggleClass('opened-burger-menu');
            });
            function analyticsPush(event, options = {}) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(Object.assign({ event }, options));
            }
            $('#analytics-form-about').one('change', 'input', function (event) {
                analyticsPush('request_personal_data_start', { 'form_field': event.target.name });
            });
        });
        if ($('section').hasClass('block3') && $('.block3__slider').length) {
            $('.block3__slider').slick({
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });
        }
        if ($('section').hasClass('block5')) {
            $('.block5').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                fade: true,
            });
        }
        $('.header__menu a[href^="#"]').click(function (e) {
            e.preventDefault();
            const id = $(this).attr('href');
            const { top } = $(id).offset();
            $('body,html').animate({ scrollTop: top }, 1000);
        });
        $('.anchor').click(function (e) {
            e.preventDefault();
            const id = $(this).attr('href');
            const { top } = $(id).offset();
            $('body,html').animate({ scrollTop: top }, 1000);
        });
        $('.popup[data-popup="form"] .exit-btn').click(function (e) {
            e.preventDefault();
            $(this).closest('.popup').fadeOut();
            $(this).closest('.popup').find('form').trigger('reset');
            $('.input-box.invalid').removeClass('invalid');
            const video = $(this).closest('.popup').find('video').get(0);
            if (video) {
                video.pause();
            }
        });
        $(document).mouseup(function (e) {
            const popupContainer = $('.popup-container');
            if (popupContainer.has(e.target).length === 0 &&
                !popupContainer.is(e.target)) {
                popupContainer.closest('.popup').fadeOut();
                popupContainer.find('.input-box.invalid').removeClass('invalid');
                popupContainer.closest('.popup').find('form').trigger('reset');
            }
        });
        $('[data-popup-btn]').click(function (e) {
            e.preventDefault();
            const popup = $(this).data('popup-btn');
            $(`.popup[data-popup=${popup}]`).fadeIn();
        });
        $('.select-box select').change(function () {
            const val = $(this).val();
            if (val == '---') {
                $(this).closest('.select-box').addClass('default');
            }
            else {
                $(this).closest('.select-box').removeClass('default');
            }
        });
        $('input[type=file]').change(function (e) {
            let fileName = '';
            const input = $(this);
            const label = input.closest('.file-box').find('label').find('span');
            if (input.get(0).files && input.get(0).files.length > 1) {
                fileName = '';
                const num = input.get(0).files.length;
                for (let i = 0; i < num; i++) {
                    fileName += `${input.get(0).files[i].name}<br>`;
                }
            }
            else {
                fileName = input.get(0).files[0].name;
            }
            if (fileName) {
                label.html(fileName);
            }
            else {
                label.html('');
            }
        });
        $('.sharebtn').click(function () {
            $(this).addClass('open');
        });
        $('.share-link').click(function (e) {
            e.preventDefault();
            window.open($(this).attr('href'), 'fbShareWindow', `height=450, width=550, top=${$(window).height() / 2 - 275}, left=${$(window).width() / 2 - 225}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`);
            return false;
        });
        function selectText(elementId) {
            const doc = document;
            const text = doc.getElementById(elementId);
            let range;
            let selection;
            if (doc.body.createTextRange) {
                range = document.body.createTextRange();
                range.moveToElementText(text);
                range.select();
                console.log(range);
            }
            else if (window.getSelection) {
                selection = window.getSelection();
                range = document.createRange();
                range.selectNodeContents(text);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
        $('.copy-link').click(function (e) {
            e.preventDefault();
            selectText('for_copy');
            document.execCommand('copy');
        });
        const analyticsPush = (event, options = {}) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(Object.assign({ event }, options));
        };
        const getCookieByName = (name) => {
            const cookie = {};
            document.cookie.split(';').forEach((element) => {
                let [key, value] = element.split('=');
                cookie[key.trim()] = value;
            });
            return cookie[name];
        };
        const getGaClientId = () => {
            var _a;
            const gaClientIdFull = (_a = getCookieByName('_ga')) === null || _a === void 0 ? void 0 : _a.split('.');
            return gaClientIdFull === null || gaClientIdFull === void 0 ? void 0 : gaClientIdFull.slice(2).join('.');
        };
        const UTM_SOURCE_DIRECT_LINK = '(direct)';
        const getParamsFromUrl = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            let objGetParamsFromUrl = {};
            window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_, key, value) => (objGetParamsFromUrl[key] = value));
            const DEFAULT_VALUE = '(none)';
            let utmSource, utmMedium = DEFAULT_VALUE, utmCampaign = DEFAULT_VALUE, utmTerm = DEFAULT_VALUE, utmContent = DEFAULT_VALUE;
            const gclId = (_a = objGetParamsFromUrl.gclid) !== null && _a !== void 0 ? _a : 'NULL';
            if (objGetParamsFromUrl.hasOwnProperty('gclid')) {
                utmSource = 'google';
                utmMedium = 'cpc';
                utmCampaign = (_b = objGetParamsFromUrl.utm_campaign) !== null && _b !== void 0 ? _b : DEFAULT_VALUE;
                utmTerm = (_c = objGetParamsFromUrl.utm_term) !== null && _c !== void 0 ? _c : DEFAULT_VALUE;
                utmContent = (_d = objGetParamsFromUrl.utm_content) !== null && _d !== void 0 ? _d : DEFAULT_VALUE;
                return {
                    gclid: gclId,
                    utm_source: utmSource,
                    utm_medium: utmMedium,
                    utm_campaign: utmCampaign,
                    utm_term: utmTerm,
                    utm_content: utmContent
                };
            }
            const importantKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
            const hasImportantKeys = Object.keys(objGetParamsFromUrl).reduce((acc, key) => {
                if (importantKeys.includes(key) && !acc) {
                    return true;
                }
                return acc;
            }, false);
            if (gclId === 'NULL' && !hasImportantKeys) {
                const documentReferrer = document.referrer;
                if (documentReferrer) {
                    const getParamsBySearchSystemsNames = (documentReferrer) => {
                        const searchSystemsNames = ['bing', 'google', 'duckduckgo', 'ecosia', 'ukr', 'yahoo', 'terra'];
                        const foundSystem = searchSystemsNames.find(searchSystem => documentReferrer.includes(`${searchSystem}`));
                        const utmSource = foundSystem || documentReferrer;
                        const utmMedium = foundSystem ? 'organic' : 'referral';
                        return {
                            utm_source: utmSource,
                            utm_medium: utmMedium
                        };
                    };
                    return Object.assign({ gclid: gclId, utm_campaign: DEFAULT_VALUE, utm_term: DEFAULT_VALUE, utm_content: DEFAULT_VALUE }, getParamsBySearchSystemsNames(documentReferrer));
                }
                return {
                    gclid: gclId,
                    utm_source: UTM_SOURCE_DIRECT_LINK,
                    utm_medium: DEFAULT_VALUE,
                    utm_campaign: DEFAULT_VALUE,
                    utm_term: DEFAULT_VALUE,
                    utm_content: DEFAULT_VALUE
                };
            }
            if (gclId === 'NULL') {
                utmSource = (_e = objGetParamsFromUrl.utm_source) !== null && _e !== void 0 ? _e : DEFAULT_VALUE;
                utmMedium = (_f = objGetParamsFromUrl.utm_medium) !== null && _f !== void 0 ? _f : DEFAULT_VALUE;
                utmCampaign = (_g = objGetParamsFromUrl.utm_campaign) !== null && _g !== void 0 ? _g : DEFAULT_VALUE;
                utmTerm = (_h = objGetParamsFromUrl.utm_term) !== null && _h !== void 0 ? _h : DEFAULT_VALUE;
                utmContent = (_j = objGetParamsFromUrl.utm_content) !== null && _j !== void 0 ? _j : DEFAULT_VALUE;
                return {
                    gclid: gclId,
                    utm_source: utmSource,
                    utm_medium: utmMedium,
                    utm_campaign: utmCampaign,
                    utm_term: utmTerm,
                    utm_content: utmContent
                };
            }
            return {
                gclid: gclId,
                utm_source: UTM_SOURCE_DIRECT_LINK,
                utm_medium: DEFAULT_VALUE,
                utm_campaign: DEFAULT_VALUE,
                utm_term: DEFAULT_VALUE,
                utm_content: DEFAULT_VALUE
            };
        };
        const getUserIp = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch('https://ip.devotedcg.com');
                if (!response.ok) {
                    return;
                }
                const data = yield response.json();
                const { ip } = data;
                return ip;
            }
            catch (error) {
                console.error('Error fetching IP:', error);
                return null;
            }
        });
        const getParametersForAnalytic = () => __awaiter(this, void 0, void 0, function* () {
            const userAgent = navigator.userAgent;
            const sessionId = getCookieByName('_ga_NYPTHT65EJ');
            const gaClientId = getGaClientId();
            const objectGetParamsFromUrl = getParamsFromUrl();
            const userIp = yield getUserIp();
            return Object.assign({ sessionId,
                gaClientId,
                userAgent,
                userIp }, objectGetParamsFromUrl);
        });
        const setCookie = ({ name, value, path = '/', domain, expires }) => {
            if (!name || !value)
                return;
            document.cookie = `${name}=${encodeURIComponent(value)};path=${path}` +
                (domain ? `;domain=${domain}` : '') +
                (expires ? `;expires=${expires};` : '');
        };
        const analyticDataLocalStorageName = 'devotedCG_analytic';
        const getParametersForAnalyticCurrent = yield getParametersForAnalytic();
        const stringGetParametersForAnalytic = JSON.stringify(getParametersForAnalyticCurrent);
        const date = new Date();
        const expiresInHours = 24 * 365;
        date.setTime(date.getTime() + (expiresInHours * 60 * 60 * 1000));
        const expires = date.toUTCString();
        setCookie({
            name: analyticDataLocalStorageName,
            value: stringGetParametersForAnalytic,
            path: '/',
            domain: '.devotedcg.com',
            expires: expires
        });
        $(document).on('click', '.analytic', function (event) {
            return __awaiter(this, void 0, void 0, function* () {
                const { eventLabel, eventType } = event.target.dataset;
                if (!eventType)
                    return;
                const str = eventType.replaceAll(' ', '_').toLowerCase();
                analyticsPush('button_click', { 'button_name': str, 'label': eventLabel });
            });
        });
        $('#analytics-form-about').one('change', 'input', function (event) {
            analyticsPush('request_personal_data_start', { 'form_field': event.target.name });
        });
        const movie = document.getElementById("movie");
        if (movie) {
            movie.onclick = function () {
                movie.paused ? movie.play() : movie.pause();
            };
        }
    });
});
(function () {
    const toAnimate2 = $('.svg-anim');
    function mainScroll2() {
        if (toAnimate2.not('.svg-animated').length) {
            toAnimate2.each(function () {
                const t = $(this);
                if (pageYOffset + innerHeight > t.offset().top + 50) {
                    this.transitionDuration = $(this)
                        .addClass('svg-animated')
                        .css('transition-duration');
                    this.duration =
                        this.transitionDuration.indexOf('ms') > -1
                            ? parseFloat(this.transitionDuration)
                            : parseFloat(this.transitionDuration) * 1000;
                    setTimeout(function () {
                        t.trigger('svg-animated');
                    }, this.duration);
                }
            });
        }
    }
    setTimeout(function () {
        mainScroll2();
    }, 1000);
    $(window).scroll(mainScroll2);
    window.onload = mainScroll2();
    const toAnimate = $('.to-animate');
    function mainScroll() {
        if (toAnimate.not('.animated').length) {
            toAnimate.each(function () {
                const t = $(this);
                if (pageYOffset + innerHeight > t.offset().top + 50) {
                    this.transitionDuration = $(this)
                        .addClass('animated')
                        .css('transition-duration');
                    this.duration =
                        this.transitionDuration.indexOf('ms') > -1
                            ? parseFloat(this.transitionDuration)
                            : parseFloat(this.transitionDuration) * 1000;
                    setTimeout(function () {
                        t.trigger('animated');
                    }, this.duration);
                }
            });
        }
    }
    setTimeout(function () {
        mainScroll();
    }, 1000);
    $(window).scroll(mainScroll);
    window.onload = mainScroll();
})();
