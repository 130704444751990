import Masonry from 'masonry-layout';
const initIdentifyTalentGallery = () => {
    const grid = document.querySelector('.js-identify-items');
    if (!grid)
        return;
    const IdentifyTalentGallery = new Masonry(grid, {
        itemSelector: '.js-identify-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
    });
    console.log('initIdentifyTalent', IdentifyTalentGallery);
};
export default initIdentifyTalentGallery;
