const changeHeroBgImages = () => {
    const desktopImages = window.document.querySelectorAll('.desktop > .js-clients-h-bg-img');
    const mobileImages = window.document.querySelectorAll('.mobile > .js-clients-h-bg-img');
    if (!desktopImages && !mobileImages)
        return;
    const images = window.innerWidth <= 480 ? mobileImages : desktopImages;
    function showImage(index) {
        images.forEach((image, i) => {
            const actionType = i === index ? 'add' : 'remove';
            image.classList[actionType]('show');
        });
    }
    let currentIndex = 0;
    function rotateImages() {
        showImage(currentIndex);
        currentIndex = (currentIndex + 1) % images.length;
        setTimeout(rotateImages, 4000);
    }
    rotateImages();
};
export default changeHeroBgImages;
