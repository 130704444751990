import Swiper, { Navigation, Pagination } from 'swiper';
function initSwipers() {
    const featuredArtistsSlider = new Swiper('.js-featured-artists', {
        speed: 1000,
        autoHeight: true,
        slidesPerView: 1,
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: '.featured-artists-next',
            prevEl: '.featured-artists-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            300: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            851: {
                slidesPerView: 1,
                spaceBetween: 0,
                width: 740,
            },
            1031: {
                slidesPerView: 1,
                spaceBetween: 0,
                width: 800,
            },
            1200: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            1370: {
                width: 985,
                slidesPerView: 1,
                spaceBetween: 0,
            },
            1470: {
                width: 1090,
                slidesPerView: 1,
                spaceBetween: 0,
            },
        },
    });
    featuredArtistsSlider.on('slideChange', () => {
        console.log('Slide changed');
    });
}
const Sliders = { initSwipers };
export default Sliders;
